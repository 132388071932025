import Merchant from '@/mixins/merchant.js'

export default {
    mixins: [Merchant],
    methods: {
        getFAQURL() {

            const subdomain = window.location.hostname.split('.')[0]

            if (subdomain === 'darty-assurance') {

                return 'https://darty-assurance-greendid.crisp.help/fr/'
            }

            if (subdomain == 'assurantprivilegepass') {

                return 'https://app-greendid.crisp.help/fr/'
            }

            return ''
        },
        getFAQDeliveryURL() {

            const subdomain = window.location.hostname.split('.')[0]

            if (subdomain === 'darty-assurance') {

                return 'https://darty-assurance-greendid.crisp.help/fr/article/comment-dois-je-emballer-mon-produit-1nf9kkg/'
            }

            if (subdomain == 'assurantprivilegepass') {

                return 'https://app-greendid.crisp.help/fr/article/ou-dois-je-deposer-mon-colis-1steb2c/'
            }

            return ''
        },
        getFAQContactURL() {

            const subdomain = window.location.hostname.split('.')[0]

            if (subdomain === 'darty-assurance') {
    
                return 'https://darty-assurance-greendid.crisp.help/fr/article/comment-puis-je-vous-contacter-si-jai-une-question-icwl53/'
            }

            if (subdomain == 'assurantprivilegepass') {

                return 'https://app-greendid.crisp.help/fr/article/comment-puis-je-vous-contacter-si-jai-une-question-1u7sp1h/'
            }

            return ''
        },
        getContactEmail() {

            const subdomain = window.location.hostname.split('.')[0]

            return 'contact-' + subdomain + '@greendid.com'
        },
        getFAQContributionURL() {

            const subdomain = window.location.hostname.split('.')[0]

            if (subdomain == 'assurantprivilegepass') {
    
                return 'https://app-greendid.crisp.help/fr/category/mon-avantage-20-assurant-privilege-pass-1jkmr00/'
            }

            return ''
        }
    }
}
