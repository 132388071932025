<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/BasketV2.vue'
import orderMixin from '../../mixins/order'
import merchantMixin from '@/mixins/merchant'
import orderService from '../../services/order'
import AcceptedOffer from '../../components/basket/AcceptedOfferV2.vue'
import ProductCard from '../../components/basket/ProductCardV2.vue'
//import ServicesCard from '../../components/basket/ServicesCard.vue'
import ProductCaption from '../../components/basket/ProductCaption.vue'
import StandardModal from '../../components/StandardModalV2.vue'
import CancelOrderButton from '@/components/basket/CancelOrderButton.vue';

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, ProductCaption, StandardModal, CancelOrderButton},
    mixins: [orderMixin, merchantMixin],
    data() {
        return {

            file: null,
            isUploadLoading: false,
            error: '',
            isLoading: false,
            modalCancelOrder: false
        }
    },
    created() {
      
        this.$store.commit('setCurrentStep', 40)
    },
    computed: {
        isMobile() {
            
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) === true
        },
        isOnlyDarty() {

            if (!this.isOrderLoaded) return false

            if (this.order.merchant.id == 9535101279 && this.order.merchant_contract.type == 'Assurance Multimédia Foyer') {

                return true
            }

            return false
        }
    },
    methods:{
        addFile(e) {

            let droppedFiles = e.dataTransfer != undefined ? e.dataTransfer.files : e.target.files

            if (!droppedFiles) return
            ([...droppedFiles]).forEach(f => {
                this.file = f
            });
        },
        removeFile() {

            this.file = null
        },
        async upload() {

            this.error = ''
            this.isLoading = true

            let invoice = this.file
            this.isUploadLoading = true

            try {
                
                await orderService.uploadInvoice(this.order.public_id, invoice)
            }
            catch (error) {

                this.$tracking.logEvent('Invoice upload error', {'Error': error})
            }
            this.isUploadLoading = false

            this.$router.push({name: 'orderTransfer', params: {id: this.order.public_id}})

            this.isLoading = false
        },
        async cancelOrder() {

            await orderService.cancelOrderById(this.orderId)
            
            this.$router.push({name: 'searchForm'})
        },
        closeModalCancelOrder() {

            this.modalCancelOrder = false

            this.$tracking.logEvent('Cancel order closed', {'Order ID': this.orderId})
        },
    },
    watch: {
        identityType() {

            if (this.identityType != '') {

                this.askForUpdateLater = false;
            }
        },
        askForUpdateLater() {

            if (this.askForUpdateLater == true) {
                this.identityType = ''
            }
        },
        isOrderLoaded() {

            this.$tracking.pageView('kyc', 'order', {'Order ID': this.orderId, 'Coupon Type': this.order.coupon_type})
        }
    }
}
</script>
<style scoped>
    .btn-outline-danger {
        background-color: white !important;
    }
    .btn-outline-danger:hover {
        background-color: #f34770 !important;
    }
    #selectCard:hover {
        border-color: #21d5b6 !important;
    }
</style>
<template>
    <basket-layout>
        <div v-if="this.order">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-7 ms-md-5">
                    <div class="col-md-11">
                        <h3>Ma facture</h3>
                        <span v-if="isOnlyDarty">Merci de fournir la facture d'achat DARTY de votre produit.</span>
                        <span v-else>Merci de fournir la facture d'achat de votre produit.</span>
                        <div class=" col-md-7 mt-4">
                            <div v-if="!isMobile" style="min-height:100px" class="form-group border  p-2 bg-white" v-cloak @drop.prevent="addFile" @dragover.prevent>
                                <label>Faites glisser votre fichier ici :</label>
                            </div>
                            <div class="form-group border p-2 bg-white">
                                <label v-if="isMobile">Ajoutez votre fichier ici : </label>
                                <label v-if="!isMobile">Ou ajoutez votre fichier ici : </label>
                                <input id="file" type="file" @change="addFile" accept="image/*,.pdf">
                            </div>
                        </div>
                        <div v-if="error == 'Veuillez choisir un type de pièce d\'identité'" class="mt-4 alert alert-danger">
                            <span>Merci de fournir une pièce d'identité ou de cocher la case ci-dessous.</span>
                        </div>
                        <button :disabled="file == undefined" class="btn btn-success shadow-lg text-white text-center mt-4 col-md-5 col-12" @click="upload()">
                            Continuer
                             <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="orderTotalPrice" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign" :paymentType="order.payment_type" :contributionPrice="order.contribution_amount" />
                        <cancel-order-button v-if="order.active_counter_offer === null && order.accepted_counter_offer === null" v-model="modalCancelOrder" @click="modalCancelOrder = true"/>
                    </div>
                    <product-caption/>
                </div>
            </div>
        </div>
        <standard-modal v-if="modalCancelOrder">
            <template v-slot:body>
                <p> Êtes vous sûr de vouloir annuler votre reprise ?</p>
                <button role="button" class="btn btn-outline-danger col-7" @click="cancelOrder()">Confirmer l'annulation</button>
                <button role="button" class="btn btn-primary offset-1 col-3 px-0"  @click="closeModalCancelOrder()"><span class="text-white">Retour</span></button>
            </template>
        </standard-modal>
    </basket-layout>
</template>
