export default {
    methods: {
        getLocalImage(name) {

            return '/assets/img/' +  name
        },
        getMerchantAsset(merchandId, assetName) {

            return '/assets/img/' + merchandId + '/' + assetName
        },
        getCategoryAsset(category) {

            return  '/assets/img/category/' + category.img_filename
        },
        getCategoryAssetGeneric(categoryId, brand) {

            if (brand === 'APPLE') {

                return '/assets/img/category/category-' + categoryId + '-APPLE-generic.png'
            }
            
            return '/assets/img/category/category-' + categoryId + '-generic.png'
        },
        getCguLink() {

            if (this.merchant == undefined) return ''

            return this.merchant.white_label_domain ? process.env.VUE_APP_S3_ASSETS_URL + '/docs/' + this.getDocSuffix() + '_cgu.pdf' : process.env.VUE_APP_S3_ASSETS_URL + '/docs/greendid_cgu_' + this.subdomain + '.pdf'
        },
        getLegalNoticeLink() {

            if (this.merchant == undefined) return ''

            return this.merchant.white_label_domain ? process.env.VUE_APP_S3_ASSETS_URL + '/docs/' + this.getDocSuffix() + '_mentions_legales.pdf' : process.env.VUE_APP_S3_ASSETS_URL + '/docs/greendid_mentions_legales_' + this.subdomain + '.pdf'
        },
        getPrivacyPolicyLink() {

            if (this.merchant == undefined) return ''

            return this.merchant.white_label_domain ? process.env.VUE_APP_S3_ASSETS_URL + '/docs/' + this.getDocSuffix() + '_politique_de_confidentialite.pdf' : process.env.VUE_APP_S3_ASSETS_URL + '/docs/greendid_politique_de_confidentialite_' + this.subdomain + '.pdf'
        },
        getDocSuffix() {

            let suffix = 'greendid'

            if (this.merchant.white_label_domain && (this.merchant.white_label_domain.includes('greendid') || this.merchant.id == 3919794380)) {

                suffix = this.merchant.name.toLowerCase()
                suffix = suffix.replace(' ', '-')
                suffix = suffix.replace('é', 'e')
            }
            else if (this.merchant.white_label_domain) {

                suffix = this.merchant.white_label_domain
                suffix = suffix.replace('.', '-')
            }

            return suffix
        },
    },
    computed: {
        subdomain() {

            return window.location.hostname.split('.')[0]
        },
    }
}
