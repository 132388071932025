<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import basketMixin from '@/mixins/basket'
import ImportFiles from "@/components/basket/ImportFiles.vue";
import categoryService from '@/services/category.js'
import brandService from '@/services/brand.js'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import listingService from '@/services/listing'
import merchantMixin from '@/mixins/merchant'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { uuid } from 'vue-uuid'
import StandardModal from '@/components/StandardModal.vue'
import Conditions from '@/components/basket/Conditions.vue'
import contractMixin from '@/mixins/contract'

export default {

    mixins: [basketMixin, merchantMixin, contractMixin],
    components: {vSelect, BasketLayout, ImportFiles, VuePhoneNumberInput, StandardModal, Conditions}, 
    data() {
        return {

            isLoading: false,
            listingData: {

                category:'',
                brand:'',
                model:'',
                year:'',
                condition:'',
                details:'',
                email:null,
                firstName:'',
                lastName:'',
                phoneNumber:'',
                isPhoneNumberValid:false,
                importedFiles:[],
                productType: '',
                conditions: null
            },
            adStep:1,
            categories:[],
            conditionsFilter: this.$t('BASKET_CONDITION_LABEL.OTHER'),
            brands : [],
            inputDisabled: false,
            email: '',
            emailMessage: null,
            inputAdditionalDisabled: false,
            listingStatus:'',
            listingUpdated: null,
            errorMessage: null,
            helperModal: null,
            areConditionsOK: false
        }
    },
    created() {

        if (this.listingId == undefined && this.selectedContract == undefined) {

            this.$router.push({name: 'home'})
        }

        this.loadMerchantBySubdomain()
        if (this.merchantId) {

            this.loadCategories()
        }
        this.loadBrands()
        this.getListing()
        this.$tracking.pageView('listing', 'listing', {'Listing ID': this.$route.params.id})
        this.setCurrentStep(20)
    },
    methods: {
        async createFromListing() {

            this.isLoading = true
            let token = await this.recaptcha()

            this.updateDetails()
            
            try {

                this.isLoading = true
                let response = await listingService.createListing(this.listingData, this.merchant.id, token, 'lr' + uuid.v4(), this.selectedContract.public_id, this.source)
                this.listingUpdated = response.data
                this.$router.push({path: '/listing/' + this.listingUpdated.public_id, params: {listingId: this.listingUpdated.public_id}})
            }
            catch (error) {

                this.errorMessage = 'Une erreur est survenue, veuillez contacter notre service client pour plus d\'informations'

                this.$tracking.logEvent('Create listing from listing page error', {'Page Name': 'listing', 'Page Category': 'listing', 'Error': 'Create listing error'})
            }

            this.isLoading = false
        },
        async updateFromListing() {

            this.isLoading = true
            let token = await this.recaptcha()
            
            this.updateDetails()

            try {

                this.isLoading = true
                let response = await listingService.updateListing(this.listingData, this.listingId, token, true)
                this.listingUpdated = response.data
            }
            catch (error) {

                if (error.response.status == 422) {

                    this.errorMessage = 'Une erreur est survenue, veuillez contacter notre service client pour plus d\'informations'
                }
                this.$tracking.logEvent('Update listing from listing page error', {'Page Name': 'listing', 'Page Category': 'listing', 'Error': 'Update listing error'})
            }

            this.isLoading = false
        },
        async getListingById(listingId) {

            this.isLoading = true

            try {
                    
                let response = await listingService.get(listingId, true)
                let data = response.data
    
                this.listingData.brand = data.brand.id
                this.listingData.category = data.category.id
                this.listingData.model = data.model
                this.listingData.details = data.details
                this.listingData.productType = data.product_type
                this.listingData.conditions = data.conditions
    
                this.listingStatus = data.status
                if (data.status != 'created') {
    
                    this.listingData.condition = data.product_condition_id
                    this.listingData.year = data.year
                    this.listingData.details = data.details
                    this.listingData.price = data.price
                    this.listingData.contributionAmount = data.contribution_amount
    
                    this.inputAdditionalDisabled = true
                }
    
                this.inputDisabled = true
            }
            catch (error) {

                if (error.response.status == 401) {

                    this.$router.push({path: '/order/' + error.response.data.order_public_id})
                }

                if (error.response.status == 404) {

                    this.$router.push({path: '/searchForm'})
                }
            }
            this.isLoading = false
        },
        async loadCategories() {

            let response = await categoryService.list(this.merchantId)
            this.categories = this.sortCategories(response.data)

            if (this.$route.query.cid != undefined) {

                this.listingData.category = this.$route.query.cid
            }
        },
        getCategory(categoryId) {

            return this.categories.find(element => element.id == categoryId)
        },
        async loadBrands() {

            let response = await brandService.list()
            this.brands = response.data
        },
        updateFiles(payload) {

            this.listingData.importedFiles = payload.files
        },
        nextStep() {

            if (this.adStep === 3) {

                if (this.listingId != null) {
                    
                    this.updateFromListing()
                }
                else {
                    
                    this.createFromListing()
                }
            }

            this.adStep++
 
            if (this.adStep === 4) {

                this.getListing()
            }
        },
        updatePhoneNumber(data) {

            if (data.isValid) {

                this.listingData.phoneNumber = data.formatNational
                this.listingData.isPhoneNumberValid = data.isValid
            }
        },
        async recaptcha() {

            let token = ''
            if (process.env.VUE_APP_ENVIRONMENT == 'local') {

                token = '1'
            }
            else {

                await this.$recaptchaLoaded()

                token = await this.$recaptcha('createListing')
            }

            return token
        },
        toAcceptOfferVue() {

            this.$router.push({name:'listingAcceptOffer', params: {id:this.$route.params.listingId}})
        },
        getListing() {

            if (this.listingId != null) {

                this.getListingById(this.listingId)
            }
        },
        styleLabel(label) {

            return '<strong>' + label.replace('-', '</strong> - ')
        },
        async getConditions(categoryId, brandId) {

            let response =  await listingService.getConditions(categoryId, brandId)

            return response.data
        },
        updateCondition(val) {
            
            let conditionsLength = Object.values(this.listingData.conditions).length
            let areConditionsOk = true

            for (let i = 1; i <= conditionsLength; i++) {

                if (this.listingData.conditions[i].name == val.name) {

                    this.listingData.conditions[i]['val'] = val.val
                    this.listingData.conditions[i]['title'] = val.title
                }

                if (this.listingData.conditions[i].answer != undefined) {

                    if (this.listingData.conditions[i].val != undefined) {

                        if (this.listingData.conditions[i].answer != this.listingData.conditions[i].val) {

                            areConditionsOk = false
                        }
                    }
                }
            }

            this.areConditionsOK = areConditionsOk
        },
        sortCategories(categories) {

            return categories.sort((a, b) => { 
            
                if (a.name === 'Autres') {

                    return 1
                }

                if (b.name === 'Autres') {

                    return -1
                }

                if (a.name < b.name) {

                    return -1
                }
                
                if (a.name > b.name) {

                    return 1
                }

                return 0
            })
        },
        updateDetails() {

            let conditionsLength = Object.values(this.listingData.conditions).length
            for (let i = 1; i <= conditionsLength; i++) {

                let element = this.listingData.conditions[i]
                if (element.title != undefined) {

                    let answer = 'Non'
                    if (element.val) {

                        answer = 'Oui'
                    }

                    let title = element.title.replace('<strong>', '')
                    title = title.replace('</strong>', '')

                    this.listingData.details += title + ' : ' + answer + ' \n'
                }
            }
        } 
    },
    computed: {
        yearCheck() {

            return this.listingData.year.length < 4 ? false : true
        },
        detailsCheck() {

            return this.listingData.details.length < 10 ? false : true
        },
        phoneTranslations() {

            return {

                countrySelectorLabel: 'Code pays',
                countrySelectorError: 'Choisir un pays',
                phoneNumberLabel: 'Numéro de téléphone portable',
                example: 'Exemple :'
            }
        },
        listingId() {

            return this.$route.params.listingId != null ? this.$route.params.listingId : null
        },
        brandIdToName() {

            return this.brands.filter(brand => brand.id === this.listingData.brand)
        },
        listingSteps() {

            if (this.adStep == 1 && this.listingData.category != '' && this.listingData.condition != '' && this.listingData.brand != '' && this.listingData.model != '' && this.yearCheck && this.detailsCheck) {

                return true
            }

            if (this.adStep == 2 && this.listingData.importedFiles.length >= 1) {
            
                return true
            }

            if (this.adStep >= 3 && this.listingData.email != null && this.listingData.firstName != '' && this.listingData.lastName != '' && this.listingData.isPhoneNumberValid) {

                return true
            }

            return false
        },
        listingBrandId() {

            return this.listingData.brand
        },
        listingCategoryId() {

            return this.listingData.category
        },
        source() {

            return this.$store.getters.source
        }
    },
    watch: {
        email() {

            this.listingData.email = null
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(this.email)) {
                
                this.listingData.email = this.email
                this.emailMessage = null
            } 
            else {
                
                this.listingData.email = null
                this.emailMessage = 'Veuillez rentrer un email valide'
            }
        },
        listingUpdated() {

            this.getListing()
        },
        async listingBrandId() {

            if (this.listingCategoryId != undefined && this.listingCategoryId != '') {

                let conditions = await this.getConditions(this.listingCategoryId, this.listingBrandId)
                this.listingData.conditions = conditions.conditions
                this.listingData.productType = conditions.type
            }
        },
        async listingCategoryId() {

            if (this.listingBrandId != undefined && this.listingBrandId != '') {

                let conditions = await this.getConditions(this.listingCategoryId, this.listingBrandId)
                this.listingData.conditions = conditions.conditions
                this.listingData.productType = conditions.type
            }
        },
        merchantId(newValue) {

            if (newValue != undefined) {

                this.loadCategories()
            }
        }
    }   
}
</script>
<template>
    <basket-layout>
        <div v-if="adStep <= 4 && merchant" class="row d-flex flex-lg-row justify-content-between">
            <div v-if="listingStatus === 'to_validate' || listingStatus == 'to_validate_greendid'"  class="shadow-lg mt-3 mt-md-5 p-4 col-12 col-lg-8 border bg-white rounded d-flex flex-column align-items-center right-column">
                <h3>Votre demande de reprise a bien été prise en compte</h3>
                <p>Vous obtiendrez une réponse d'ici 48h</p>
            </div>
            <div v-if="listingStatus === 'accepted'"  class="shadow-lg mt-3 mt-md-5 p-4 col-12 col-lg-8 border bg-white rounded d-flex flex-column justify-content-center right-column">
                <h3>Votre annonce a été acceptée !</h3>
                <p>Pour que la reprise soit validée vous allez devoir fournir une pièce d'identité, signer un bon de céssion puis expédier votre produit.</p>
                <div class="d-flex flex-row justify-content-between m-3">
                    <button @click="toAcceptOfferVue" class="btn btn-primary">
                        Accepter l'offre de {{ (listingData.price / 100 + listingData.contributionAmount / 100).toFixed(2) }} €
                    </button>
                </div>
            </div>
            <div v-if="listingStatus === 'refused'"  class="shadow-lg mt-3 mt-md-5 p-4 col-12 col-lg-8 border bg-white rounded d-flex flex-column align-items-center right-column">
                <h3>Votre annonce a été refusée</h3>
                <p>Vous pouvez proposer un autre produit</p>
            </div>
            <div v-if="errorMessage != null" class="alert alert-danger mt-3 mt-md-5" role="alert">
                {{ errorMessage }}
            </div>
            <h3 v-if="errorMessage == ''" class="mt-3 mt-md-5">
                Votre Annonce 
                <span v-if="(listingStatus === '' || listingStatus === 'created') && adStep < 4">
                    (Etape {{ adStep }}/3) 
                </span>
                <span v-if="isLoading" class="spinner-border spinner-border-lg mr-2" role="status" aria-hidden="true"></span>
            </h3>
            <div v-if="adStep == 1" id="product-infos" class="shadow-lg mt-4 border bg-white rounded col-12 col-lg-8">
                <div class="p-3">
                    <legend>Informations du produit</legend>
                    <legend class="text-muted fs-6">Tous les champs sont obligatoires afin de passer à l'étape suivante</legend>
                    <div class="mb-3">
                        <label class="form-label">Catégorie</label>
                        <v-select :disabled="inputDisabled" v-model="listingData.category" :options="categories" label="name" :reduce="category => category.id"/>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Marque</label>
                        <v-select :disabled="inputDisabled" v-model="listingData.brand" :options="brands" label="name" :reduce="brand => brand.id"/>  
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Etat du produit</label>
                        <div class="row">
                            <div class="col-11">
                                <select class="form-select" :disabled="inputAdditionalDisabled" v-model="listingData.condition">
                                    <option value="" disabled></option>
                                    <option  v-bind:key="index" :value="parseInt(index)" v-for="condition, index in conditionsFilter" placeholder="Condition" v-html="condition">
                                    </option>
                                </select>
                            </div>
                            <div class="col-1 d-flex align-items-center justify-content-center">
                                <i @click="helperModal = 'CONDITION'" style="cursor: pointer;" class="bi bi-question-circle text-success font-size-2rem"></i>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Modèle</label>
                        <input class="form-control" :disabled="inputDisabled" v-model="listingData.model" type="text">
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Année d'achat</label>
                        <input class="form-control" :disabled="inputAdditionalDisabled" v-model="listingData.year" type="number" min="2000" max="2099" maxlength="4">
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Détails</label>
                        <textarea class="form-control" :disabled="inputAdditionalDisabled" v-model="listingData.details" rows="6" type="textarea" ></textarea>
                        <div :class="{'text-danger' : !detailsCheck}" class="form-text">(10 caractères minimum)</div>
                    </div>
                    <conditions :merchant="merchant" @updateCondition="updateCondition" :conditions="this.listingData.conditions" :productType="this.listingData.productType" />
                </div>
            </div>


            <div v-if="adStep == 2" id="product-photos" class="shadow-lg mt-4 border bg-white rounded col-12 col-lg-8">
                <div class="p-3">
                    <legend>Facture d'achat du produit</legend>
                    <div v-if="adStep >= 2 && adStep < 4" class="mb-3">
                        <import-files :adStep="adStep" @updateFiles="listingData.importedFiles = $event" :files="listingData.importedFiles" :maxNbrFiles="1"></import-files>
                    </div>
                </div>
            </div>

            <div v-if="adStep == 3" id="seller" class="shadow-lg mt-4 border bg-white rounded col-12 col-lg-8">
                <div class="p-3">
                    <div v-if="adStep >= 3 && adStep < 4" class="text-muted m-4" id="personal-infos">
                        <legend>Vos coordonnées</legend>
                        <div class="mb-3">
                            <label class="form-label">Email</label>
                            <input class="form-control" :disabled="inputAdditionalDisabled" v-model="email" type="email">
                            <p v-if="emailMessage"> {{ emailMessage }}</p>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Prénom</label>
                            <input class="form-control" :disabled="inputAdditionalDisabled" v-model="listingData.firstName" type="text">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Nom</label>
                            <input class="form-control" :disabled="inputAdditionalDisabled" v-model="listingData.lastName" type="text">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Numéro de téléphone</label>
                            <vue-phone-number-input :disabled="inputAdditionalDisabled" :translations="phoneTranslations" v-model="listingData.phoneNumber" v-on:update="updatePhoneNumber" :preferred-countries="['FR', 'BE', 'DE']" :default-country-code="'FR'" />
                        </div>                                
                    </div>
                </div>
            </div>
  
            <div class="col-12 col-lg-8 mt-4" v-if="listingStatus === 'created' || (listingStatus === '' && adStep < 4)">
                <div class="row">
                    <div class="col-2">
                        <button @click="adStep--" :disabled="adStep <= 1 || adStep > 3" class="btn btn-secondary w-100">Retour</button>
                    </div>
                    <div class="col-10">
                        <button :disabled="listingSteps === false || adStep > 3 || areConditionsOK == false" @click="nextStep" class="btn btn-primary w-100">
                            <span v-if="adStep < 3">Valider ces informations (Etape {{ adStep }}/3)</span>
                            <span v-else-if="adStep === 3">Soumettre cette annonce <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span></span>
                        </button>
                    </div>
                </div>
            </div>
            <standard-modal v-if="helperModal && listingData.productType != ''" :title="$t('BASKET_CONDITION.' + helperModal + '.' + listingData.productType + '.TITLE')" :closeClass="'btn-success'" :closeLabel="'Fermer'" @close="helperModal = null">
                <template v-slot:body>
                    <p v-html="$t('BASKET_CONDITION.' + helperModal + '.' + listingData.productType + '.DESCRIPTION')"></p>
                    <ul v-if="helperModal == 'CONDITION'">
                        <li v-html="styleLabel($t('BASKET_CONDITION_LABEL.' + listingData.productType + '.1'))"></li>
                        <li v-html="styleLabel($t('BASKET_CONDITION_LABEL.' + listingData.productType + '.2'))"></li>
                        <li v-html="styleLabel($t('BASKET_CONDITION_LABEL.' + listingData.productType + '.3'))"></li>
                        <li v-html="styleLabel($t('BASKET_CONDITION_LABEL.' + listingData.productType + '.4'))"></li>
                        <li v-html="styleLabel($t('BASKET_CONDITION_LABEL.' + listingData.productType + '.5'))"></li>
                    </ul>
                </template>
            </standard-modal>
        </div>
    </basket-layout>
</template>

<style scoped>

.right-column {

    height: fit-content;
}

</style>