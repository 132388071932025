<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import basketMixin from '@/mixins/basket'
import merchantMixin from '@/mixins/merchant'
import basketService from '@/services/basket'
import orderService from '@/services/order'
import ProductCard from '@/components/basket/ProductCardV2.vue'
import ConditionSummary from '@/components/basket/ConditionSummaryV2.vue'
import InsuranceOffer from '@/components/basket/InsuranceOffer.vue'
import ProductCaption from '@/components/basket/ProductCaption.vue'

export default {

    mixins: [basketMixin, merchantMixin],
    components: {BasketLayout, ProductCard, ConditionSummary, InsuranceOffer, ProductCaption}, 
    data() {
        return {
            conditionAccepted: true,
            offerValidated: false,
            needToRefresh: false,
            isLoading: false,
            orderRunning: false,
            selectedCampaign: null,
            selectedPaymentType: null,
            withContribution: false
        }
    },
    created() {
      
        this.$tracking.pageView('accept offer', 'basket', {'Basket ID': this.basketId})
        this.setCurrentStep(30)
    },
    watch: {
        offerValidated(val) {

            if (val == true && this.$store.getters.autoAcceptOffer == true) {

                this.acceptOffer()
            }
        },
        isBasketLoaded() {

            if (this.basket != undefined)
                this.validateOffer(this.basket.public_id)
        }
    },
    methods: {
        async validateOffer(basketPublicId) {

            try {

                let response = await basketService.validateOffer(basketPublicId)
                this.$store.commit('setBasket', response.data)
                this.offerValidated = true

                if (this.basketOffer.price == 0 && this.basket.bonus.length > 0) {

                    this.selectedCampaign = this.basket.bonus[0]
                }
            }
            catch (error) {
            
                if (error.request && error.request.status == 419) {

                    this.needToRefresh = true
                    this.$tracking.logEvent('Accept offer error', {'Basket ID': basketPublicId, 'Page Name': 'accept offer', 'Page Category': 'basket', 'Error': 'Need to refresh'})
                }
                else if (error.response.data.message == 'CONDITION_NOT_ACCEPTED') {

                    this.$tracking.logEvent('Accept offer error', {'Basket ID': basketPublicId, 'Page Name': 'accept offer', 'Page Category': 'basket', 'Error': 'Condition not accepted'})
                    this.conditionAccepted = false
                }
            }
        },
        async acceptOffer() {

            this.isLoading = true
            try {

                let response = await orderService.create(
                    this.basket.product_id, 
                    this.basket.merchant_id,
                    this.basket.merchant_product_id,
                    this.basket.merchant_user_id,
                    this.basket.merchant_order_id,
                    this.basket.merchant_product_img,
                    this.basket.merchant_order_date,
                    this.basket.merchant_product_price,
                    this.basket.conditions,
                    this.basket.public_id,
                    this.basket.invoice_filename,
                    this.selectedCampaign != undefined ? this.selectedCampaign : this.$store.getters.selectedCampaign,
                    this.basket.offer_template != undefined ? this.basket.offer_template.id : null,
                    this.basket.offer_specs != undefined ? this.basket.offer_specs.id : null,
                    this.basket.offer_specs != undefined ? this.basket.offer_specs.specs : null,
                    this.basket.type,
                    this.selectedPaymentType != undefined ? this.selectedPaymentType : this.$store.getters.selectedPaymentType,
                    null,
                    this.withContribution
                )

                this.$tracking.logEvent('Offer Accepted', {'Basket ID': this.basketId, 'Price': this.basketOffer.price,'Auto Accepted': this.$store.getters.autoAcceptOffer})
                this.$store.commit('setAutoAcceptOffer', false)
                this.$router.push({name: 'orderKyc', params:{id: response.data.public_id}, query:{f: 1}})
            }
            catch (error) {

                if (error.response.status == 401) {

                    this.$store.commit('setAutoAcceptOffer', true)
                    this.$router.push({name: 'basketSignIn', params:{id: this.basket.public_id}, query:{return: this.$route.fullPath}})
                }
                if (error.response.status == 500 && error.response.data.message) {

                    this.orderRunning = true
                }
            }
            this.isLoading = false
        },
        vouchSelected(payload) {

            this.selectedCampaign = payload.campaign
            this.selectedPaymentType = payload.type
            this.withContribution = payload.withContribution
            this.$store.commit('setSelectedCampaign', this.selectedCampaign)
            this.$store.commit('setSelectedPaymentType', this.selectedPaymentType)
        },
        getTypeLabel(couponName) {

            let label = ''
            if (this.basketOffer.price > 0) {

                label = this.$options.filters.capitalize(couponName)
            }

            if (this.basket.bonus.length > 0) {

                if (label != '') {

                    label = label + ' et'
                }

                if (label == '') {

                    label = 'code de réduction'
                }
                else {

                    label = label + ' code de réduction'
                }
            }

            return label
        },
    },
    computed: {
        isBestOfferLoaded() {

            return this.offerValidated == true && this.basket != null && this.basketOffer != null
        },
        isBestOfferLoadedAndFilled() {

            return this.isBestOfferLoaded && this.basketOffer != undefined
        },
        merchantOrderHistoryUrl() {

            return this.merchant.orders_history_url
        },
        productConditions() {

            return this.basket.product_conditions
        },
        basketConditions() {

            return this.basket.conditions != null ? JSON.parse(this.basket.conditions) : undefined
        },
        isMerchantLoaded() {

            return this.merchant != undefined
        },
        basketOffer() {

            if (this.basket == undefined) return null

            if (this.basket.attached_to == 'specs') return this.basket.offer_specs

            return this.basket.product_offer
        },
        percentPrice() {

            if (this.basket == undefined || this.basket.merchant_product_price == undefined || this.basket.merchant_product_price == 0) return null

            if (this.basketOffer == undefined || this.basketOffer.price_total == undefined) return null

            return Math.round(this.basketOffer.price_total / this.basket.merchant_product_price * 100)
        }
    },
    mounted() {

        window.scrollTo(0,0)
    }
}
</script>
<template>
    <basket-layout>
        <div v-if="this.basket">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11">
                        <h3>Offre de reprise</h3>
                        <div v-if="isBestOfferLoaded && isMerchantLoaded">
                            <g-vouches @vouchSelected="vouchSelected"
                                :merchant="merchant"
                                :offer="basketOffer"
                                :bonus="basket.bonus"
                                :hasContributionChoice="merchant.has_contribution_choice == 1 ? true : false"
                                :contributionName="$t('GENERAL.' + merchant.id + '.CONTRIBUTION_NAME')"
                            />
                            <div class="row">
                                <div class="col-md-7 mb-2 pe-md-0 pe-3">
                                    <button :disabled="isLoading" class="btn btn-success shadow-lg text-white w-100 text-center my-4" @click="acceptOffer">
                                        J'ACCEPTE L'OFFRE
                                        <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                            <div v-if="orderRunning" class="alert alert-danger mb-4">
                                Une revente pour ce produit est actuellement en cours de processus. Veuillez retourner sur votre <a :href="merchantOrderHistoryUrl">historique d'achat</a> et cliquer sur le bouton 'Reprendre ma vente'.
                            </div>
                        </div>
                        <div v-else-if="!isBestOfferLoaded && !conditionAccepted && isMerchantLoaded">
                            <div class="card shadow-lg text-center my-4">
                                <div class="card-body">
                                    <h5 class="card-title"><strong>AUCUNE OFFRE DE REPRISE</strong></h5>
                                    <p>Nous n'avons pas d'offre disponible pour ces conditions de reprise.</p>
                                    <a class="btn btn-info" role="button" v-if="merchantOrderHistoryUrl" :href="merchantOrderHistoryUrl">Revenir à mes commandes</a>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="!isBestOfferLoaded && needToRefresh" class="card text-center shadow-lg">
                            <div class="card-body">
                                <h5 class="card-title text-danger">ERREUR</h5>
                                <p>Une erreur est survenue.<br/>Merci de recharger la page.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="basket" :bonus="selectedCampaign" />
                        <insurance-offer v-if="isBestOfferLoadedAndFilled && basketOffer.price_contribution != undefined && basketOffer.price_contribution > 0" :totalPrice="basketOffer.price_total" :price="basketOffer.price_greendid" :insurancePrice="basketOffer.price_contribution" :percentPrice="percentPrice" />
                        <g-max-best-offer v-if="isBestOfferLoadedAndFilled && basketOffer.price_contribution == undefined" 
                            :isMax="false" 
                            :maxBestOffer="basketOffer" 
                            :merchant="merchant"  
                            :bonus="selectedCampaign" 
                            :paymentType="selectedPaymentType" 
                            :withContribution="withContribution"
                            :contributionName="$t('GENERAL.' + merchant.id + '.CONTRIBUTION_NAME')"
                            :contributionLegalText="$t('GENERAL.' + merchant.id + '.CONTRIBUTION_LEGAL_TEXT')"
                        />
                        <condition-summary :basketId="basketId" :productConditions="basketConditions" :productType="basket.product_type" />
                    </div>
                    <product-caption/>
                </div>
            </div>
        </div>
    </basket-layout>
</template>