<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import merchantMixin from '@/mixins/merchant'
import basketService from '@/services/basket'
import StandardModal from '@/components/StandardModal.vue'
import wordingMixin from '@/mixins/wording'
import contractMixin from '@/mixins/contract'
import faqMixin from '@/mixins/faq'

export default {

    components: {BasketLayout, StandardModal},
    mixins: [merchantMixin, wordingMixin, contractMixin, faqMixin],
    data() {

        return {
            error: '' 
        }
    },
    created() {

        this.loadMerchantBySubdomain()

        this.$tracking.pageView('contracts', 'home')
    },
    computed: {
        source() {

            return this.$store.getters.source
        }
    },
    methods: {
        async createBasket(contract) {

            this.error = ''

            if (!contract.can_do_resale) {

                return;
            }

            try {
            
                let response = await basketService.create(contract.email, contract.product_sku.toString(), this.merchantId, contract.public_id, contract.product_name, '', contract.start_at, (contract.product_price / 100).toString(), this.source) 

                let basketId = response.data.basketId
                if (basketId != undefined) {

                    this.$router.push({name: 'basketInfo', params: {id: basketId}})
                }
                else {

                    let url = response.data.url
                    if (url != undefined) {

                        window.location = url
                    }
                }
            }
            catch (error) {

                if (error.response.status == 404) {

                    this.$router.push({name: 'listing'})
                }
                else {

                    this.error = "Une erreur est survenue. Veuillez contacter le support."
                }
            }
        },
        goToSearchForm() {

            this.$router.push({name: 'searchForm'})
        },
        setSelectedContract(contract) {

            this.$store.commit('setSelectedContract', contract)
        },
        unsetSelectedContract() {

            this.$store.commit('unsetSelectedContract')
        },
    },
}

</script>

<template>
    <basket-layout>
        <div class="row mt-3 mt-md-5">
            <div class="col ms-md-5">
                <h3 class="text-primary text-decoration-underline" style="cursor: pointer" @click="unsetSelectedContract">Mes contrats</h3>
                <div class="row g-2 mt-4">
                    <div v-if="selectedContract">
                        <div class="row">
                            <div class="card col-12 col-md-4">
                                <div class="card-body">
                                    N° <strong>{{ selectedContract.public_id }}</strong>
                                    <br/>{{ selectedContract.type }}
                                    <br/>{{ getWording('START_DATE') }} : {{selectedContract.start_at | date}}
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div v-if="selectedContract.product_id" class="card col-12 col-md-4 mt-2">
                                <div class="card-body">
                                    <div class="card-title"><strong>Mon produit assuré</strong></div>
                                    <div>{{ selectedContract.product_brand }} {{ selectedContract.product_name }}</div>
                                    <div v-if="selectedContract.can_do_resale" class="mt-2 text-nowrap">
                                        <button @click="createBasket(selectedContract)" class="btn btn-tertiary text-light text-uppercase">Revendre</button>
                                    </div>
                                    <div v-else class="mt-2 text-danger">
                                        {{ getWording('CANT_DO_RESELL') }}
                                    </div>
                                </div>
                            </div>
                            <div v-else class="card col-12 col-md-4 mt-2">
                                <div class="card-body">
                                    <div class="card-title"><strong v-html="getWording('RESELL_NO_PRODUCT_TITLE')"></strong></div>
                                    <div>{{ getWording('RESELL_NO_PRODUCT_DESCRIPTION') }}</div>
                                    <div v-if="selectedContract.can_do_resale" class="mt-2 text-nowrap">
                                        <button class="btn btn-tertiary text-light text-uppercase" @click="goToSearchForm()">Revendre</button>
                                    </div>
                                    <div v-if="!selectedContract.can_do_resale" class="mt-2 text-danger">
                                        {{ getWording('CANT_DO_RESELL') }}
                                    </div>
                                    <div v-if="!selectedContract.can_have_contribution" class="mt-2 text-danger">
                                        {{ getWording('CANT_HAVE_CONTRIBUTION') }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="selectedContract.product_id || !selectedContract.can_do_resale" class="card col-12 col-md-4 offset-md-1 mt-2">
                                <div class="card-body">
                                    <div class="card-title"><strong v-html="getWording('RESELL_OTHER_TITLE')"></strong></div>
                                    <div>{{ getWording('RESELL_OTHER_DESCRIPTION') }}</div>
                                    <div class="mt-2 text-nowrap">
                                        <button class="btn btn-tertiary text-light text-uppercase" @click="goToSearchForm()">Revendre</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="merchant && merchant.has_contribution_choice" class="row mt-5">
                            <div class="card card-info col-12 bg-tertiary text-light">
                                <div class="card-body">
                                    <i class="bi bi-info-circle"></i>
                                    Que dois-je faire pour bénéficier de mon bonus +20% ? Quand est-ce le mieux pour l’activer?   
                                    <br/>Vous pouvez vous rendre sur la page <a class="text-light" :href="getFAQContributionURL()" target="_blank">Centre d’Aide</a> pour comprendre comment utiliser mon Bonus +20%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else v-for="contract in contracts" :key="contract.number" class="col-12 col-lg-4">
                        <div class="card h-100">
                            <div class="card-body d-flex flex-column flex-grow-1">
                                <div class="card-title">N° <strong>{{ contract.public_id }}</strong><br/>{{ contract.type }}<br/>{{ getWording('START_DATE') }} : {{contract.start_at | date}}</div>
                                <p class="text-center" v-if="contract.product && contract.product.name"><small><strong>{{ contract.product.name }}</strong></small></p>
                                <div class="mt-auto">
                                    <button @click="setSelectedContract(contract)" class="btn btn-tertiary text-light text-uppercase">
                                        <strong>Revendre un produit</strong>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <standard-modal v-if="error" @close="error = ''" :closeLabel="'fermer'">
            <template v-slot:body>
                <p class="text-center">{{ error }}</p>
            </template>
        </standard-modal>
    </basket-layout>
</template>
