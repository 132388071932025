import Vue from 'vue'
import Vuex from 'vuex'
import basketModule from './modules/basket'
import authModule from './modules/auth'
import contractModule from './modules/contract'
import merchanttModule from './modules/merchant'
import parametersModule from './modules/parameters'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        basket: basketModule,
        auth: authModule,
        contract: contractModule,
        merchant: merchanttModule,
        parameters: parametersModule
    }
})
