import amplitude from 'amplitude-js'

export default {
    install(Vue) {
        
        let apiKey = process.env.VUE_APP_AMPLITUDE_KEY
        amplitude.getInstance().init(apiKey)

        // eslint-disable-next-line
        Vue.prototype.$tracking = {
            
            setUserId(userId) {

                amplitude.getInstance().setUserId(userId)
            },
            pageView(pageName, pageType, properties) {

                if (properties == null)
                    properties = {}

                if (pageName != null) 
                    properties['Page Name'] = pageName
                
                if (pageType != null)
                    properties['Page Category'] = pageType

                let hostname = window.location.hostname
                let subdomain = ''
                if (hostname.includes('greendid')) {

                    subdomain = hostname.substring(0, hostname.indexOf('.'))
                }
                else {

                    subdomain = hostname
                }
                properties['Subdomain'] = subdomain

                amplitude.getInstance().logEvent('Page View', properties)
            },
            logEvent(eventName, properties) {

                if (properties == null)
                    properties = {}

                let hostname = window.location.hostname
                let subdomain = ''
                if (hostname.includes('greendid')) {

                    subdomain = hostname.substring(0, hostname.indexOf('.'))
                }
                else {

                    subdomain = hostname
                }
                properties['Subdomain'] = subdomain
                
                amplitude.getInstance().logEvent(eventName, properties)
            }
        }
    }
}
