import axios from 'axios'

export default {

    createFromSearchForm(merchantId, oid, pid, sid, img, token, merchantUserId, merchantOrderId, source) {

        let orderId = oid === null ? null : parseInt(oid)
        let productId = pid === null ? null : parseInt(pid)
        let specsId = sid === null ? null : sid

        var path = '/api/customer/searchFormListings'

        var data = {

            merchant_id: merchantId,
            offer_template_id: orderId,
            product_id: productId,
            specs_id: specsId,
            img: img,
            token: token,
            merchant_user_id: merchantUserId,
            merchant_order_id: merchantOrderId,
            source: source
        }

        return axios.post(path, data)
    },

    get(listingPublicId, withConditions = false) {

        let path = '/api/customer/listings/' + listingPublicId
        if (withConditions == true) {

            path = path + '?wc=1'
        }

        return axios.get(path);
    },
    createListing(listingData, merchantId, token, merchantUserId, merchantOrderId, source) {
        
        var formData = new FormData();
        var path = '/api/customer/listings'

        formData.append('merchant_id', merchantId)
        formData.append('category_id', listingData.category)
        formData.append('brand_id', listingData.brand)
        formData.append('model', listingData.model)
        formData.append('product_condition_id', listingData.condition)
        formData.append('email', listingData.email)
        formData.append('first_name', listingData.firstName)
        formData.append('last_name',  listingData.lastName)
        formData.append('phone_number', listingData.phoneNumber)
        formData.append('year', listingData.year)
        formData.append('details', listingData.details)
        formData.append('token', token)
        formData.append('invoice', listingData.importedFiles[0].file)
        formData.append('merchant_user_id', merchantUserId)
        formData.append('merchant_order_id', merchantOrderId)

        if (source != undefined) {  
        
            formData.append('source', source)
        }

        return axios.post(path, formData, {headers: {'Content-Type': 'multipart/form-data' }})
    },
    updateListing(listingData, listingPublicId, token, hasInvoice) {

        var formData = new FormData();
        var path = '/api/customer/listings/' + listingPublicId
        
        let photo2 = listingData.importedFiles.length >= 2 ? listingData.importedFiles[1].file : null
        let photo3 = listingData.importedFiles.length >= 3 ? listingData.importedFiles[2].file : null
        let photo4 = listingData.importedFiles.length === 4 ? listingData.importedFiles[3].file : null
        
        formData.append('product_condition_id', listingData.condition)
        formData.append('email', listingData.email)
        formData.append('first_name', listingData.firstName)
        formData.append('last_name',  listingData.lastName)
        formData.append('phone_number', listingData.phoneNumber)
        formData.append('year', listingData.year)
        formData.append('token', token)
        if (hasInvoice) {

            formData.append('invoice', listingData.importedFiles[0].file)
        }
        else {

            formData.append('photo1', listingData.importedFiles[0].file)
        }
        formData.append('details', listingData.details)

        if (photo2) {

            formData.append('photo2', photo2)
        }

        if (photo3) {

            formData.append('photo3', photo3)
        }

        if (photo4) {

            formData.append('photo4', photo4)
        }

        return axios.post(path, formData, {headers: {'Content-Type': 'multipart/form-data' }})
    },
    getConditions(categoryId, brandId) {

        let path = '/api/customer/listings/conditions'

        path = path + '?bid=' + brandId + '&cid=' + categoryId

        return axios.get(path);
    }
}
